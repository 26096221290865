<template>
  <el-dialog
    top="20vh"
    class="el-dialog-cus"
    v-bind="attributes"
    :visible="visible"
    :before-close="beClose"
    append-to-body
    :close-on-click-modal="false"
    v-on="on"
  >
    <div class="pop-info-box">
      <h5 class="pop-tits">
        <span>个人信息填写</span>
        <el-link class="custon-close" @click="cancel"><i class="el-icon-close"></i></el-link>
      </h5>
      <div class="info-form">
        <el-form :model="inheritorForm" :rules="rules" ref="inheritorForm" label-width="100px" class="">
          <el-form-item label="姓  名" prop="inheritorName">
            <el-input v-model="inheritorForm.inheritorName" placeholder="请输入传承人姓名"></el-input>
          </el-form-item>

          <el-form-item label="手机号码" prop="inheritorPhone">
            <el-input v-model="inheritorForm.inheritorPhone" placeholder="请输入手机号"></el-input>
          </el-form-item>

          <el-form-item label="短信验证" prop="captchaCode">
            <el-input v-model="inheritorForm.captchaCode" placeholder="请输入短信验证码">
              <el-button slot="suffix" class="message-btn" @click="getCaptcha" :disabled="countdown !== 0">
                <template v-if="countdown === 0">发送验证码</template>
                <template v-else>{{ countdown }}s后重新发送</template>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input
              v-model="inheritorForm.idcard"
              placeholder="请输入身份证号"
              @change="handleIdCardChange"
            ></el-input>
          </el-form-item>

          <el-form-item label="匹配项目" prop="system">
            <el-input
              v-model="matchProjects"
              placeholder="自动识别匹配传承人非遗项目"
              readonly
              :title="matchProjects"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="form-btn" @click="submitForm('inheritorForm')" :disabled="!matchProjects"
              >立即创建
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- <div slot="footer">
      <el-button @click="cancel" plain>{{btnTxt[0]}}</el-button>
      <el-button @click="confirm" type="primary" v-if="btnTxt[1]">{{btnTxt[1]}}</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      default: () => ({
        width: '420px',
        title: '提示',
        center: true,
        btnTxt: ['取消', '确定']
      })
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    const { config } = this,
      { btnTxt } = config
    return {
      visible: false,
      attributes: config,
      btnTxt,
      on: this.getDialogEvents(),
      countdown: 0,
      inheritorForm: {
        inheritorName: '',
        inheritorPhone: '',
        idcard: '',
        captchaKey: '',
        captchaCode: ''
      },
      matchProjects: '',
      rules: {
        inheritorName: [
          { required: true, message: '请输入传承人姓名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        inheritorPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        idcard: [{ required: true, message: '请输入身份证', trigger: 'blur' }],
        captchaCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleIdCardChange(IdCard) {
      this.axios.get('/api/portal/inheritor/auth/list-by-idcard?idcard=' + IdCard + '&current=1').then(res => {
        let data = res.data
        let projectList = []
        if (data && data.length > 0) {
          data[0].relaProject.forEach(project => {
            projectList.push(project.projectName)
          })
        }
        this.matchProjects = projectList.join(' ')
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit('update:inheritorForm', this.inheritorForm)
          this.$emit('update:active', 1)
          this.visible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    open(ok) {
      this.ok = ok
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    confirm() {
      let cancel = () => this.cancel()
      this.ok(cancel)
      this.autoClose && cancel()
    },
    beClose(done) {
      done()
      this.beforeClose()
      this.cancel()
    },
    getCaptcha() {
      this.axios
        .get('/api/portal/inheritor/auth/sms/tobeInheritor?mobile=' + this.inheritorForm.inheritorPhone)
        .then(res => {
          this.$message.success('发送成功')
          this.inheritorForm.captchaKey = res.data.key
          // this.inheritorForm.captchaCode = res.data.code
          this.countdown = 60
          let timer = setInterval(() => {
            this.countdown--
            if (this.countdown === 0) {
              clearInterval(timer)
            }
          }, 1000)
        })
    },
    getDialogEvents() {
      let { close } = this.config || {},
        events = {}

      if (close && typeof close == 'function') {
        Object.assign(events, {
          close
        })
      }

      return events
    }
  }
}
</script>

<style lang="scss">
.el-dialog__header {
  display: none;
}
.el-dialog--center .el-dialog__body {
  padding: 0;
}
.el-dialog {
  background: transparent;
}
</style>
